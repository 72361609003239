<template>
  <div class="flix-container flix-form-group">
    <div class="flix-row">
      <div class="flix-col-md-4 flix-col-md-flix-offset-4">
        <div class="flix-well flix-text-center">
          <div class="flix-form-group">
            <div class="flix-html-h1" >
              <flixIcon :id="'keys'"/>
            </div>
            <div class="flix-html-h2">
              {{ $t('message.add', {name: $t('message.password')}) }}
            </div>
          </div>
          <transition name="flixFadeIn">
            <div>
              <div v-if="!date">
                <div class="flix-alert flix-alert-danger">{{ $tc('message.noEntry', 1) }}</div>
              </div>
              <form @submit.prevent="changePassword()" v-else>
                <transition name="flixFadeIn">
                <div class="flix-form-group">
                  <input autocomplete="new-password" type="password" v-model="password" class="flix-form-control" spellcheck="false" :placeholder="$t('message.password')"/>
                </div>
                </transition>
                <transition name="flixFadeIn">
                <div class="flix-form-group" v-if="password.length > 3">
                  <input autocomplete="new-password" type="password" v-model="password2" class="flix-form-control" spellcheck="false" :placeholder="$t('message.repeatPassword')"/>
                </div>
                </transition>
                <transition name="flixFadeIn">
                  <div class="flix-form-group" v-if="password.length > 3 && checkpassword">
                    <button type="submit" class="flix-btn flix-btn-block flix-btn-default">{{ $t("message.save", {name: $t('message.password')}) }}</button>
                  </div>
                </transition>
              </form>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data: false,
      password: '',
      password2: '',
      checkpassword: false,
      date: true
    }
  },
  watch: {
    password (val) { this.checkpassword = false; if (val === this.password2) { this.checkpassword = true } },
    password2 (val) { this.checkpassword = false; if (val === this.password) { this.checkpassword = true } }
  },
  methods: {
    changePassword () {
      if (!this.checkpassword) {
        return false
      }
      this.$flix_post({
        url: 'user/set_password',
        data: {
          user: this.data.user,
          pass: this.password
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.$router.push({ name: 'dashboard' })
          }
        }.bind(this)
      })
    },
    checkDate (date) {
      if (date.getTime() < new Date().getTime()) {
        this.date = false
      }
    }
  },
  mounted () {
    this.data = atob(atob(this.$route.params.id))
    if (this.data) {
      this.data = JSON.parse(this.data)
    }
    this.checkDate(new Date(this.data.date))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
